@font-face {
    font-family: 'DINRegular';
    src: url('../fonts/din-regular-webfont.woff') format('woff');
    font-display: swap;
}

@font-face {
  font-family: 'NittiLight';
  src: url('../fonts/nitti-light-v40.woff') format('woff');
  font-display: swap;
}
