/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}



/**
 * Basic styling
 */
body {
    font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
    color: $text-color;
    background-color: $background-color;
    -webkit-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern" 1;
    -moz-font-feature-settings: "kern" 1;
    -o-font-feature-settings: "kern" 1;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}



/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: $base-font-weight;
}



/**
 * Links
 */
a {
    color: $text-color;
    text-decoration: underline;
    display: inline;
    &:visited {
        color: lighten($text-color, 33%);
    }

    &:hover {
        color: $brand-color;
        text-decoration: none;
    }
}



/**
 * Blockquotes
 */
blockquote {
    color: $grey-color;
    border-left: 4px solid $grey-color-light;
    padding-left: $spacing-unit / 2;
    font-size: 18px;
    letter-spacing: -1px;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}



/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    background-color: #eef;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: auto;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}

.row {
    margin-top: 50px;
}


/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

section.container {
    margin-top: 175px;

}
.section-header {
    position: relative;
    width: fit-content;
    @media only screen and (max-width: 480px) {
        margin-top: 20px;
    }
}

h2 {
    background-color: #111;
    color: white;
    text-transform: uppercase;
    font-family: $accent-font-family;
    margin: 12px 0px;
    padding: 0px 16px;
}

h2.post-title {
    background-color: white;
    margin: 0px 3px;
    padding: 0px;
}

.section-pullquote {
    font-family: $accent-font-family;
    font-size: 50px;
    line-height: 65px;
    width: 80%;
    margin-top: 50px;
    @media only screen and (max-width: 480px) {
        font-size: 30px;
        line-height: 40px;
        text-shadow: none;
        width: 100%;
    }
}

.section-content {
    /* font-family: $accent-font-family; */
    font-size: 22px;
    line-height: 32px;
    margin-top: 50px;
    width: 90%;
    p {
        @media only screen and (max-width: 480px) {
        font-size: 20px;
        line-height: 30px;
        }
    }
    
    
    @media only screen and (max-width: 480px) {
        width: 100%;
    }
    li {
        padding-left: 10px;
    }
}

.section-image {
    position: relative;
    width: 100%;
    @media only screen and (max-width: 480px) {
        width: 120%;
    }
    &.right {
        margin-left: 20%;
        p {
            text-align: left;
        }
        @media only screen and (max-width: 480px) {
            margin-left: 0;
        }
    }
    &.left {
        margin-left: -20%;
        p {
            text-align: right;
        }
    }
    p {
        font-size: 16px;
        line-height: 31px;
        font-family: $accent-font-family;
    }

    // Only direct children, to ensure other img (e.g. icons) are not resized
    >img {
        width: 100%;
    }
}

.full {
    position: relative;
    display: block;
    width: 100%;
    height: 480px;
    overflow: hidden;
    background-color: rgb(0,0,0);
    @media only screen and (max-width: 480px) {
        height: 540px;
    }

    img.bg {
        min-height: 480px;
        width: 100%;
        display:block;
        margin:auto;
        object-fit: cover;
        @media only screen and (max-width: 480px) {
            height: 100%;
        }
    }

    .title {
        position: absolute;
        top: 20%;
        text-transform: uppercase;
        font-family: $accent-font-family;
        font-size: 90px;
        line-height: 90px;
        color: #FFFFFF;
        word-spacing: 100vw;
        @media only screen and (max-width: 480px) {
            font-size: 50px;
            line-height: 70px;
        }
    }

    &.pull-quote {
        color: white;
        font-size: 28px;
        line-height: 38px;
        font-family: $accent-font-family;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: fit-content;
        @media only screen and (max-width: 480px) {
            flex-direction: column;
        }

        p {
            display: none;
            margin: 5%;
            @media only screen and (max-width: 480px) {
                font-size: 21px;
            }
            &.visible {
                display: block;
            }
        }
        // default to display the 0th quote
    
        .quote {
            font-size: 80px;
        }
        .attribution {
            font-size: 21px;
            margin-top: 20px;
            @media only screen and (max-width: 480px) {
                font-size: 18px;
            }
        }
        .next {
            padding: 10% 5% 10% 0;
            width: 200px;
            text-align: center;
            @media only screen and (max-width: 480px) {
                margin: 10% 0;
            }
        }
    }
}

.course-heading {
    font-family: $accent-font-family;
    margin-bottom: 5rem;

    .heading-text {
        font-size: 90px;
        line-height: 1;
        text-transform: uppercase;
        margin: 0 -10px 3rem;

        @media only screen and (max-width: 480px) {
            font-size: 45px;
        }
    }
}

.speaker-images {
    margin: 0;
    margin-top: 2rem;
    margin-bottom: 1rem;
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    li {
        list-style: none;
        margin: 0;
        padding: 0;

        img {
            height: 88px;
            width: 88px;
            border-radius: 100%;
        }
    }
}

.speaker-details {
    font-family: $accent-font-family;
    font-weight: bold;
    margin-bottom: 2rem;
    font-size: 1rem;
}

.speaker-description {
    font-family: $accent-font-family;
}

.modulelist {
    margin: 0;
    margin-top: 2rem;
    margin-bottom: 1rem;
    list-style: none;
    padding: 0;
    gap: 1rem;

    & > li {
        counter-increment: item;
    }

    li {
        padding-left: calc(20px + 1rem);
        font-family: $accent-font-family;
        position: relative;

        &:before {
            content: counter(item, decimal-leading-zero);
            position: absolute;
            top: 0;
            left: 0;
        }

        h4 {
            background-color: #000000;
            padding: 0.5rem 1rem;
            margin-bottom: 1rem;
            color: #ffffff;
            display: inline-block;
        }

        ul, li {
            margin: 0;
            list-style: none;
            padding: 0;

            &:before {
                content: none;
            }
        }

        ul {
            margin-bottom: 2rem;
        }
    }
}

.eventlist {
    margin: 0;
    margin-top: 2rem;
    margin-bottom: 1rem;
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    @media only screen and (max-width: 480px) {
        flex-direction: column;
    }

    li {
        width: calc(33.33% - 1rem);
        font-family: $accent-font-family;

        @media only screen and (max-width: 480px) {
            width: 100%;
        }

        .event-dates {
            background-color: #000000;
            padding: 0.5rem 1rem;
            margin-bottom: 1rem;
            color: #ffffff;
        }

        h4 {
            font-weight: bold;
        }
    }
}

.quote-blocks {
    margin: 0;
    margin-top: 2rem;
    margin-bottom: 1rem;
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    @media only screen and (max-width: 480px) {
        flex-direction: column;
    }

    li {
        background-color: #000000;
        padding: 2rem;
        flex: 0.3;
        color: #ffffff;
        font-family: $accent-font-family;

        @media only screen and (max-width: 768px) {
            flex: 1;
        }
    }
}

.checklist {
    margin: 0;
    margin-top: 2rem;
    margin-bottom: 1rem;
    list-style: none;
    padding: 0;
    gap: 1rem;
    font-family: $accent-font-family;

    h4 {
        font-weight: bold;
    }

    li {
        position: relative;
        max-width: 800px;
        padding-left: calc(40px + 1rem);

        &:before {
            content: "";
            height: 40px;
            width: 40px;
            background-color: #000;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyMSIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0ibTIuNTc0IDkuNDYxIDUuNjQ3IDUuNjQ0TDIxLjMxMS43MSAyNCAzLjE2IDguMzQzIDIwLjM3NiAwIDEyLjAzMmwyLjU3NC0yLjU3WiIvPjwvc3ZnPg==");
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.full-width-container {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background: #000;
    color: #fff;
}

.cta {
    font-family: $accent-font-family;
    margin-top: 5rem;
    margin-bottom: -13rem;

    .container {
        padding-bottom: 4rem;
    }

    a {
        color: #fff;
    }

    .cta-date {
	 font-size: 50px;
	 margin-bottom: 2rem;
	 line-height: 1;
    }

    .cta-button {
	 margin-bottom: 2rem;

	 a {
            display: inline-block;
            text-decoration: none;
            color: #000;
            background-color: #fff;
            font-weight: 700;
            border: 2px solid #fff;
            border-radius: 0.5rem;
            padding: 0.5rem 2rem;

            &:hover {
		background-color: #000;
		color: #fff;
            }
	}
    }
}

#quotes {
    font-family: $accent-font-family;
    margin-top: 5rem;

    .container {
        padding-bottom: 4rem;
    }

    .quote-text {
        font-size: 24px;
        margin-bottom: 1rem;
    }

    .quote-attribution {
        display: block;
        text-indent: -1em;
        padding-left: 1em;
        max-width: 700px;

        &:before {
            content: "—\00a0"; /* em dash followed by a non-breaking space */
            display: inline-block;
            text-indent: 0;
        }
    }
}

#instructors {
    margin: 0;
    margin-top: 2rem;
    margin-bottom: 1rem;
    list-style: none;
    padding: 0;
    gap: 1rem;
    font-family: $accent-font-family;

    .instructor-name {
        background-color: #000000;
        padding: 0.5rem 1rem;
        margin-bottom: 1rem;
        color: #ffffff;
        display: inline-block;
    }

    .instructor {
        display: flex;
        gap: 2rem;
        margin-bottom: 3rem;

        .instructor-image {
            img {
                width: 217px;
                height: 217px;
                border-radius: 100%;

                @media only screen and (max-width: 768px) {
                    width: 88px;
                    height: 88px;
                }
            }
        }

        .instructor-info {
            flex: 1;
            width: 100%;
            max-width: 600px;
        }
    }
}

.carousel-container {
    max-width: 1000px;
    position: relative;
    margin: auto;
}

.carousel-slide {
    display: none;
}

.prev-button, .next-button {
    cursor: pointer;
    position: absolute;
    top: 30px;
    width: auto;
    margin-top: -22px;
    margin-left: -60px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 24px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    text-decoration: none;
}

.next-button {
    right: 0;
    border-radius: 3px 0 0 3px;
    margin-right: -60px;
}

.carousel-num {
    font-size: 22px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
    right: 0;
}

.prev-button:hover, .next-button:hover {
    background-color: rgba(0,0,0,0.8);
}
