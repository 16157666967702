@charset "utf-8";

// if you try to run 'gem update' or similar and it moves you to
// jekyll-sass-converter >=3.0, you will find this no longer compiles.
// That is because the versions are not backward compatible and I got
// tired of trying to figure out the changes, which are many.  See
// https://jekyllrb.com/news/2022/12/21/jekyll-sass-converter-3.0-released/
// for a start.
//
// 2024-06-04 mikey

// Our variables
$base-font-family: "DINRegular", Helvetica, Arial, sans-serif;
$accent-font-family: "NittiLight", Monaco, monospace;

$base-font-size:   20px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
    "base"
    ,"layout"
    ,"syntax-highlighting"
    ,"font"
    ,"grid"
;
