*{margin:0; padding:0; widows: 3; orphans: 3;}

body {
    @media only screen and (max-width: 768px) {
        p {
            font-size: 14px;
        }
    }
}

.site-header {
    min-height: 56px;
    height: 112px;
    margin-top:0;
    // Positioning context for the mobile navigation icon
    position: relative;
    transition: opacity .5s;
    background-color: #0D0D0D;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    @media only screen and (max-width: 880px) {
        flex-direction: column;
        height: 140px;
    }

    .logo {
        background-color: white;
        margin-top: 32px;
        @media only screen and (max-width: 880px) {
            width:100%!important;
            text-align: center;
        }

        img {
            width: auto;
            max-height: 25px;
            margin: 2% 20%;
            @media only screen and (max-width: 480px) {
                max-height: 50px;
                width: 80%;
                margin: 2% 0;
            }
        }
    }


    .callus {
        height: 100%;
        margin: 2% 10% 0 0;
        text-align:right;
        line-height: 1;
        font-family: $accent-font-family;
        font-size: 21px;
        color:white;
        a {
            z-index: 100;
            color: white;
            margin-left: 5px;
        }


        @media only screen and (max-width: 880px) {
            clear:both;
            width: 100%!important;
            text-align: center;
            display: flex;
            justify-content: space-evenly;
        }
    }
}

/**
 * Site footer
 */
.site-footer {
    background: rgb(0,0,0);
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
    margin-top: 200px;

    @media only screen and (max-width: 480px) {
        padding: $spacing-unit/2 0;
    } 

    .row {
        margin-top: 0;
        display: flex;
        align-items: center;
        @media only screen and (max-width: 480px) {
            flex-direction: column-reverse;
            align-items: baseline;
        }   
    }
    .col {
        display: flex;
        align-items: end;
        @media only screen and (max-width: 480px) {
            justify-content: center;
        }
    }
    .copyright, .contact a {
        color: white;
        font-family: $accent-font-family;
    }
    .copyright {
        justify-content: flex-start;
        font-size: 26px;
        margin: 0 10%;
        @media only screen and (max-width: 480px) {
            margin: 2% 0;
            justify-content: center;
        }
    }
    .copyright img {
        margin: 0 10px;
    }
    
    .contact {
        justify-content: flex-end;
        margin: 0 10%;
        @media only screen and (max-width: 480px) {
            margin: 2% 0;
            justify-content: center;
        }
    }
    .contact a {
        font-size: 21px;
        text-decoration: underline;
    }
}


/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    font-family: $accent-font-family;
    color: $grey-color;
}

h2.post-title {
    margin: 0 auto;
}

a.post-link{
        color: rgb(0,0,0);
        text-decoration: none;
}

.post-link {
    display: block;
    font-size: 24px;
    font-family: $accent-font-family;
    line-height: 31px;
}

p {
    margin-bottom:30px;
}




/**
* Team
*/

.team-list {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 480px) {
        flex-direction: column;
        align-items: center;
    }


    div.member {
        display: block;
        min-height:240px;
        @media only screen and (max-width: 480px) {
            margin-top: 20px;
        }
        @media only screen and (max-width: 768px) {
            min-height:130px;
        }
    
        .image {
            width: 100%;
            position: relative;

            img.member {
                border-radius: 50%;
                width: 100%;
                height: auto;
                @media only screen and (hover: none) {
                    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
                }
            }
        }

        .title, .role, .toggle-profile {
            margin-top:14px;
            text-align: left;
            width: 120%;
            display:block;
            clear: both;
            font-family: $accent-font-family;
            line-height: 31px;
            font-size: 16px;
            font-weight: bold;
        }

        .title {
            font-size: 18px;
            margin-bottom: 10px;
        }
        .role {
            font-weight: normal;
        }
        .toggle-profile {
            font-size: 16px;
            text-decoration: underline;
            border: none;
            background-color: white;
        }
    }
}

.team-bio {
    display: block;
    height: 0;
    overflow: hidden;
    transition: all .5s;
    margin-top: 15px;

    .bio {
        opacity: 0;
        display: none;
        -webkit-animation: fadeInFromNone 0.5s ease-out;
        -moz-animation: fadeInFromNone 0.5s ease-out;
        -o-animation: fadeInFromNone 0.5s ease-out;
        animation: fadeInFromNone 0.5s ease-out;
        z-index: 0;

        br {
            display: block;
            margin-bottom: 10px;
            content: " ";
        }
        &.show {
            display: block;
            z-index:100;
        }
        &.animate {
            opacity: 1!important;
        }
        .title, .role {
            display: none;
            margin-bottom:10px;
            @media only screen and (max-width: 768px) {
                display: block;
            }
        }
        p {
            font-family: $accent-font-family;
        }
    }
}

.hold {
    color: rgba(0,0,0,.0);
}

/**
 * Posts
 */
.post-header {
    // margin-bottom: $spacing-unit;
    display: block;
    margin-top: 50px;
    text-align: center;
    img.main {
        float: right;
        clear: right;
        max-height: 100px;
    }
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;
    margin-top:20px;
    font-family: $accent-font-family;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    /*
    p, ol, ul, li {
        font-family: $accent-font-family;
    }
    */

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }

    img {
        display: block;
        margin: auto;
        clear: both;
        padding: 10px;
        width: 100%;
    }
}

.blog {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 480px) {
        flex-direction: column;
        align-items: center;
    }

    &.container {
        margin-top: 175px;
    }

    .placeholder {
        background: #E5E5E5;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        @media only screen and (max-width: 480px) {
            height: 160px;
        }
        img {
            width: 20%;
        }
    }
}

// Keyframe hack for the Bios
@-webkit-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-moz-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-o-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}
